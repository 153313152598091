<template>
  <div class="PageGuide" v-if="guide">
    <div class="box" v-for="(element, key) in elements" v-bind:key="key">
      <h3>{{ element.title }}</h3>
      <p>{{ element.text }}</p>

      <video v-if="element.type == 'video'" controls>
        <source :src="element.src" />
        The browser you're using doesn't support the video tag.
      </video>

      <ul v-if="element.type == 'list'">
        <li v-for="(listItem, key) in element.items" v-bind:key="key">
          <strong>{{ listItem.title }}</strong>
          <p>{{ listItem.body }}</p>
        </li>
      </ul>

      <button
        class="downloadButton"
        v-if="element.type == 'file'"
        v-on:click="downloadFile(element.src)"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageGuide",
  inject: ["router", "store"],
  computed: {
    guide: function () {
      return this.router.state.activeRoute.guide;
    },
    elements: function () {
      return this.guide.elements;
    },
  },
  methods: {
    downloadFile: function (url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style scoped>
.PageGuide {
  overflow: hidden;
  padding: 12px;
}

h3 {
  margin-bottom: 8px;
}

.box {
  padding: 16px;
  background: var(--bg);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.box:not(:first-child) {
  margin-top: 16px;
}

video {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0 0 0 16px;
}

.downloadButton {
  margin-top: 16px;
}
</style>
