<template>
  <div class="EmbedPage">
    <div class="loadingContainer" :class="{ visible: !loaded, hidden: loaded }">
      <distributor-logo :animated="true" :label="logoLabel"></distributor-logo>
    </div>
    <div
      class="embedContainer colorInvertable"
      :class="{ visible: loaded, hidden: !loaded }"
    >
      <iframe
        :src="embeddableURL"
        ref="iframe"
        v-on:load="loaded = true"
      ></iframe>
    </div>
  </div>
</template>

<script>
import DistributorLogo from "../components/DistributorLogo.vue";

export default {
  name: "EmbedPage",
  components: { DistributorLogo },
  inject: ["router", "store"],
  emits: ["sideLoad"],
  props: {
    route: Object,
  },
  data: function () {
    return {
      loaded: false,
      sourceHost: null,
      logoLabel: null,
    };
  },
  computed: {
    version: function () {
      return this.router.state.version;
    },
    username: function () {
      const un = this.store.state.userData?.preferredUsername;
      return un;
    },
    embeddableURL: function () {
      const isEasyAuth = !!this.route.payload.easyAuth;
      if (!isEasyAuth) return this.route.payload.src;

      const baseUrl = this.route.payload.src;

      const url = `${baseUrl}/.auth/login/aad?login_hint=${
        this.username
      }&post_login_redirect_uri=${encodeURI(baseUrl)}&prompt=none`;
      return url;
    },
  },
  watch: {
    version: {
      handler: function () {
        this.update();
      },
      immediate: true,
    },
  },
  methods: {
    update: function () {
      this.loaded = false;

      if (!this.route.payload.src) {
        console.error(
          `Cannot embed content. The attribute "src" was not set in the payload for "${this.route.name}".`
        );
        return;
      }

      try {
        const srcurl = new URL(this.route.payload.src);
        this.logoLabel = "Loading data from " + srcurl.hostname;
      } catch (e) {
        // If URL isn't valid (e.g. is local path), don't show a label

        this.logoLabel = null;
      }
    },
  },
};
</script>

<style scoped>
.EmbedPage {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 600px;
  flex-flow: row wrap;
  background: var(--bg);
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embedContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}
</style>
